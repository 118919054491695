export default {
  app: {
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    defaultAccountManagerEmail: 'customerservice@boardroominsiders.com',
    customerSupportEmail: 'customerservice@boardroominsiders.com',
  },
  rollbar: {
    szexQJHhkE: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    enabled: true,
  },
  host: 'https://staging.boardroominsiders.com',
  assetsHost: 'https://staging.boardroominsiders.com',
  logOutPath: '/',
  recaptcha: {
    KvujulVHW0: '6LeALYUUAAAAAApcNRf77JVgqQDWzq8flQbY-2Ks',
  },
  hubspot: {
    portalId: 'fake',
  },
  search: {
    textValueMaxLength: 100,
    queryValueMaxLength: 200,
  },
  attachments: {
    maxFileSize: '4 megabytes',
  },
  changesHighlightsClassName: 'changesHighlights',
  amplitude: {
    MpDhboSukh: 'b5a6d6d98a3f820df16f8abb517024b6',
  },
  ga: {
    v3: 'fake',
    v4: 'fake',
  },
};
